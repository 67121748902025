import { DsPlaceholder, DsRow } from '@infarm/design-system-react';
import { FC } from 'react';
import { OperationalFarm } from '../../../types';
import { UnitFilter } from '../constants';
import { GreyP } from '../style';
import { ProductionOrderModal } from './ProductionOrderModal';
import { LocationWithTaskGroup } from './types';

interface TotalOutputProps {
  isInhubLocation: boolean;
  farms: OperationalFarm[];
  selectedUnitFilter: UnitFilter;
  location: LocationWithTaskGroup;
  loading: boolean;
}

export const TotalOutput: FC<TotalOutputProps> = ({
  selectedUnitFilter,
  location,
  loading,
  isInhubLocation,
  farms
}) => {
  const totalValue =
    selectedUnitFilter === UnitFilter.PLANTS
      ? location?.tasksForDate?.totalNumberOfPlants ?? 0
      : location?.tasksForDate?.totalNumberOfTrays ?? 0;

  return (
    <DsRow>
      <div className="span-6">
        <GreyP>Output per Week</GreyP>
        {loading ? <DsPlaceholder /> : <h2>{`${totalValue} ${selectedUnitFilter}`}</h2>}
      </div>
      {isInhubLocation && (
        <ProductionOrderModal
          farmListForIndividualOrder={farms.filter(farm => !farm.isEligibleForAutomation)}
          totalFarmForGroupOrder={farms.filter(farm => farm.isEligibleForAutomation).length}
        />
      )}
    </DsRow>
  );
};
