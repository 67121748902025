import gql from 'graphql-tag';

export const HUB_BATCHES = gql`
  query BatchDetailsForHub($uuid: ID!, $date: Date!, $batchType: TaskType!) {
    location(uuid: $uuid) {
      name
      uuid
      farms: operationalFarms(farmStateNot: NEW) {
        uuid
        name
        model
        batches(date: $date, taskType: $batchType, includeCancelled: true, isNursery: false) {
          batchId
          fullBatchId: batchId(length: 24)
          batchType
          totalTrays
          cancelled
          plots {
            uuid
            name
          }
          bench {
            uuid
            operationalBenchId
          }
          tasks(includeCancelled: true) {
            uuid
            stageRecipe {
              uuid
              name
              internalCode
              weight
              plantGroup
              duration
            }
            cancelled
            completed
            trayType
            plantsOfStageRecipe
            traysPerStageRecipe
          }
        }
      }
    }
    user {
      uuid
      name
    }
  }
`;

export const PLANTING_PLAN = gql`
  query PlantingPlan($uuid: ID!, $date: Date!) {
    location(uuid: $uuid) {
      uuid
      name
      locationGroup {
        uuid
        name
      }
      plantingPlan(date: $date) {
        file
      }
    }
  }
`;
