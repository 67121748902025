import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import { DsButton, DsCard, DsPlaceholder, DsRow } from '@infarm/design-system-react';
import {
  isToday,
  formatFullDate,
  toIsoDate,
  offsetDateWithTimezone
} from '../../utils/date-functions';
import { BATCH_SHRINKAGE } from './queries';
import { ShrinkageReason, TaskType } from '../../types';
import { getTotalsRowValues } from './utils';
import { MISSING_PLANT_SHRINKAGE_NAME, HARVEST_DASHBOARD_LINK, ColumnId } from './constants';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { isValid } from 'date-fns';

export interface TotalsRow {
  totalExpected: number;
  totalSingle: number;
  totalDouble: number;
  totalMissing: number;
  totalShrinkage: number;
  batchesSubmitted: Array<string>;
  totalBatches: number;
}

/**
 * This component is not used ATM. But will be used in future
 * when we will move the `inhub-shrinkage-table` component from
 * `osd-components` package to `operations-management` package.
 **/

export const InhubShrinkagePage = () => {
  const { locationUuid, date, taskType } = useParams();
  const currentDate = offsetDateWithTimezone(date || new Date());
  const batchType = taskType!.toUpperCase() as TaskType;
  const [totalsRow, setTotalsRow] = useState<TotalsRow>({} as TotalsRow);
  const [shrinkageReasons, setShrinkageReasons] = useState<ShrinkageReason[]>([]);
  const [missingPlantReason, setMissingPlantReason] = useState<ShrinkageReason>(
    {} as ShrinkageReason
  );
  const [error, setError] = useState<Error>();

  const [getBatchShrinkage, { data, loading }] = useLazyQuery(BATCH_SHRINKAGE, {
    onCompleted: data => {
      setTotalsRow(getTotalsRowValues(data?.location?.farms));
      setMissingPlantReason(
        data?.shrinkageReasons?.find(
          (element: ShrinkageReason) => element.name === MISSING_PLANT_SHRINKAGE_NAME
        )
      );
      setShrinkageReasons(
        data?.shrinkageReasons?.filter(
          (element: ShrinkageReason) => element.name !== MISSING_PLANT_SHRINKAGE_NAME
        )
      );
      setError(undefined);
    },
    onError: error => {
      setError(error);
    }
  });

  const location = data?.location;

  useEffect(() => {
    const currDate = offsetDateWithTimezone(date || '');
    if (!locationUuid || !isValid(currDate) || !taskType) return;
    const variables = {
      uuid: locationUuid,
      date: toIsoDate(currDate),
      batchType: taskType.toUpperCase()
    };
    getBatchShrinkage({ variables });
  }, [locationUuid, date, taskType, getBatchShrinkage]);

  const downloadBatchTable = async (): Promise<void> => {
    window.print();
  };

  const updateTotalsRowByColumns = (field: ColumnId, value: number | string) => {
    switch (field) {
      case ColumnId.EXPECTED:
        setTotalsRow({
          ...totalsRow,
          totalExpected: totalsRow.totalExpected + (value as number)
        });
        break;
      case ColumnId.SINGLE:
        setTotalsRow({
          ...totalsRow,
          totalSingle: totalsRow.totalSingle + (value as number)
        });
        break;
      case ColumnId.DOUBLE:
        setTotalsRow({
          ...totalsRow,
          totalDouble: totalsRow.totalDouble + (value as number)
        });
        break;
      case ColumnId.MISSING:
        setTotalsRow({
          ...totalsRow,
          totalMissing: totalsRow.totalMissing + (value as number)
        });
        break;
      case ColumnId.SHRINKAGE:
        setTotalsRow({
          ...totalsRow,
          totalShrinkage: totalsRow.totalShrinkage + (value as number)
        });

        break;
      case ColumnId.ACTIONS:
        if (totalsRow.batchesSubmitted.includes(value as string)) {
          setTotalsRow({
            ...totalsRow,
            batchesSubmitted: [...totalsRow.batchesSubmitted, value as string]
          });
        }

        break;
    }
  };

  const fullDate = formatFullDate(currentDate);
  const urlDate = toIsoDate(currentDate);
  return (
    <>
      <Breadcrumbs
        elements={[
          {
            text: 'Locations (Gen2)',
            link: `/locations`,
            dataCy: 'InhubWorkstation__goBackFourLevels'
          },
          {
            text: data?.location?.name,
            link: `/locations/${locationUuid}/`,
            dataCy: 'InhubWorkstation__goBackThreeLevel'
          },
          {
            text: 'Workstations',
            link: `/locations/${locationUuid}/daily/${urlDate}`,
            dataCy: 'InhubWorkstation__goBackTwoLevel'
          },
          {
            text: batchType.toLowerCase(),
            link: `/locations/${locationUuid}/daily/${urlDate}/workstations/product/${batchType.toLowerCase()}`,
            dataCy: 'InhubWorkstation__goBackOneLevel'
          },
          {
            text: 'Shrinkage'
          }
        ]}
      />
      <DsRow className="header-row">
        <div className="span-8">
          <h1>Report Shrinkage</h1>
          <p className={isToday(currentDate) ? 'ds-lead today-date' : 'ds-lead'}>{fullDate}</p>
        </div>
        <div className="harvestLinkWrapper">
          <a
            href={HARVEST_DASHBOARD_LINK}
            target="_blank"
            className="metrics-link"
            rel="noreferrer"
          >
            <span className="harvestText"> View Harvest Dashboard </span>
          </a>
        </div>

        <DsButton
          label="Print Batch Table"
          icon="print"
          onClick={downloadBatchTable}
          disabled={loading || !!error}
        />
      </DsRow>

      {loading ? (
        <DsPlaceholder block />
      ) : error ? (
        <DsCard>
          <h2>Error loading data</h2>
          <p>{error.message}</p>
        </DsCard>
      ) : (
        <inhub-shrinkage-table
          shrinkageReasons={shrinkageReasons}
          missingPlantReason={missingPlantReason}
          location={location!}
          harvestDate={fullDate}
          updateTotalsRowByColumns={updateTotalsRowByColumns}
          {...totalsRow!}
        />
      )}
    </>
  );
};

export const InhubShrinkage = () => {
  const { locationUuid, date, taskType } = useParams();
  const batchType = taskType!.toUpperCase() as TaskType;

  return <inhub-shrinkage uuid={locationUuid} date={date} task={batchType}></inhub-shrinkage>;
};
