import { useQuery } from '@apollo/client';
import { DsPlaceholder } from '@infarm/design-system-react';
import { Divider } from '@material-ui/core';
import { groupBy } from 'lodash-es';
import { useParams } from 'react-router-dom';
import { Content } from '../../../components/DividerWithContent/styles';
import { sortCallback } from '../../../utils';
import { OperationSummary } from '../OperationSummary';
import { SummaryWrapper } from '../OperationSummary/style';
import { WORKSTATION_NURSERY_IN_ACRE } from '../queries';
import { Section, SectionTitle } from '../style';
import { NurseryInAcreTasksForDate } from '../types';
import { sumTrays } from '../utils';
import { NiAOverviewTable } from './NiAOverviewTable';

export const OperationOverview = () => {
  const { locationUuid, date } = useParams();

  const { data, loading } = useQuery(WORKSTATION_NURSERY_IN_ACRE, {
    variables: {
      locationUuid,
      date
    }
  });

  if (loading) return <DsPlaceholder />;

  const farms: NurseryInAcreTasksForDate[] = data?.location?.tasksForDate?.byOperationalFarm || [];

  const sortedFarms = [...farms].sort((byOperationalFarmA, byOperationalFarmB) =>
    sortCallback(byOperationalFarmA.operationalFarm.name, byOperationalFarmB.operationalFarm.name)
  );

  const loadingTasks = farms
    .flatMap(farm => {
      return farm.taskGroup.byBench;
    })
    .flatMap(byBench => byBench.taskGroup.loading.tasks);

  const groupedLoadingTasks = groupBy(loadingTasks, 'stageRecipe.name');
  const numberOfTraysToLoad = sumTrays(loadingTasks);

  const formattedTasks = Object.keys(groupedLoadingTasks)
    .map(variety => {
      const tasksOfVariety = groupedLoadingTasks[variety];
      const [{ sourceType }] = tasksOfVariety;
      const amountOfTrays = sumTrays(tasksOfVariety);
      return {
        variety,
        amountOfTrays,
        from: sourceType === 'SEEDING_STATION' ? 'Seeding' : 'NuC'
      };
    })
    .sort((taskA, taskB) => taskB.amountOfTrays - taskA.amountOfTrays);

  return (
    <>
      <SummaryWrapper>
        <Section>
          <SectionTitle>Overview</SectionTitle>
          <Divider />
        </Section>
        <Content>
          <h4>Trays to load: {numberOfTraysToLoad}</h4>
          <NiAOverviewTable data={formattedTasks} />
        </Content>
      </SummaryWrapper>
      <OperationSummary sortedFarms={sortedFarms} />
    </>
  );
};
