import { DsCard, DsColumn, DsPlaceholder, DsRow } from '@infarm/design-system-react';
import styled from 'styled-components';

export const PrintableBodyWrapper = styled.div`
  @font-face {
    font-family: 'Code39';
    src: url(code39.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  * {
    box-sizing: border-box;
  }

  body {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    color: #000000;
    font-weight: 400;
    font-size: 14pt;
    line-height: 1.4;
  }

  @media print {
    html,
    body {
      padding: 0;
      margin: 0;
    }

    @page {
      margin: 0;
      size: A4 landscape;
    }
  }
`;

export const KanbanColumn = styled(DsColumn)`
  border-right: 1px solid #cccccc;
  padding-right: 16px;
`;

export const StyledPlaceholder = styled(DsPlaceholder)`
  height: 6em;
`;

export const BatchCard = styled(DsCard)`
  background-color: #ffffff;

  img {
    float: right;
    margin-top: 8px;
  }

  &.cancelled {
    opacity: 0.5;
    h2 {
      text-decoration: line-through;
    }
    .cancelled-hint {
      font-size: 12px;
      line-height: 1.5em;
      background-color: #e00028;
      color: $red1;
      display: inline-block;
      text-decoration: none;
      border-radius: 4px;
      margin-inline-start: 8px;
      vertical-align: middle;
      padding-inline: 4px;
    }
  }
`;

export const PageHeaderRow = styled(DsRow)`
  padding-bottom: 16px;
`;

export const HeaderDate = styled.p<{ today?: boolean }>`
  font-size: 24px;
  font-weight: 400;
  color: #767676;
  ${({ today }) => (today ? 'color: #e00028 !important;' : '')}
`;

export const ErrorMessage = styled.div`
  color: #e00028;
  margin-bottom: 16px;
`;

export const FullPageBg = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  background-color: #f2f2f2;
  padding-top: 16px;
  padding-bottom: 16px;

  & > * {
    width: 100%;
    padding-left: calc(50vw - 688px);
    padding-right: calc(50vw - 688px);
    position: relative;
    align-self: center;

    @media (max-width: 1679px) {
      max-width: 100vw;
      padding-left: 127px;
      padding-right: 127px;
    }

    @media (max-width: 1279px) {
      padding-left: 24px;
      padding-right: 24px;
    }

    @media (max-width: 959px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`;
