import { DsRow } from '@infarm/design-system-react';
import { BatchWithFarm, Location, TaskType } from '../../types';
import { getBatchColumns, getBatchColumnTitles } from './utils';
import BenchIcon from '../../assets/bench.svg';
import { KanbanColumn, StyledPlaceholder, BatchCard } from './style';

interface KanbanBatchCardProps {
  batch: BatchWithFarm;
}

const KanbanBatchCard = ({ batch }: KanbanBatchCardProps) => {
  return (
    <BatchCard className={batch.cancelled ? ' cancelled' : ''}>
      <h2>
        {batch.batchId}
        {batch.cancelled && <span className="cancelled-hint">Cancelled</span>}
        <img src={BenchIcon} alt="Bench Icon" />
      </h2>
      <p className="ds-secondary">
        {batch.farm?.name}
        {batch.farm?.model !== 'ACRE' && (
          <span>
            &nbsp;&middot;&nbsp;
            {batch.plots?.map(plot => `Plot ${plot.name}`).join(', ')}
          </span>
        )}
      </p>
    </BatchCard>
  );
};

interface InhubKanbanBoardProps {
  batchType: TaskType;
  location: Location;
}

export const InhubKanbanBoard = (props: InhubKanbanBoardProps) => {
  const { location, batchType } = props;
  const [todoTitle, doneTitle] = getBatchColumnTitles(batchType);
  const [todoBatches, doneBatches] = getBatchColumns(location);
  const todoBatchCount = todoBatches.filter(b => !b.cancelled).length;
  const doneBatchCount = doneBatches.filter(b => !b.cancelled).length;
  const loading = !location;
  return (
    <DsRow>
      <KanbanColumn>
        <p className="ds-lead">
          {todoTitle} {!loading && `(${todoBatchCount})`}
        </p>
        {loading ? (
          <StyledPlaceholder block />
        ) : (
          todoBatches.map(batch => <KanbanBatchCard batch={batch} key={batch.batchId} />)
        )}
      </KanbanColumn>
      <KanbanColumn>
        <p className="ds-lead">
          {doneTitle} {!loading && `(${doneBatchCount})`}
        </p>
        {loading ? (
          <StyledPlaceholder block />
        ) : (
          doneBatches.map(batch => <KanbanBatchCard batch={batch} key={batch.batchId} />)
        )}
      </KanbanColumn>
    </DsRow>
  );
};
