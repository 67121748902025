import { useLazyQuery, useQuery } from '@apollo/client';
import { DsPlaceholder } from '@infarm/design-system-react';
import { addDays, subDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import { Error } from '../../../components/Error';
import { OperationalFarm } from '../../../types';
import { getMonday, toIsoDate } from '../../../utils/date-functions';
import { NurseryMode } from '../../constants';
import { Section } from '../../PlantingSchedule/style';
import { NurseryControls } from './NurseryControls';
import { NurserySystems } from './NurserySystems';
import { NurseryUtilisation } from './NurseryUtilisation';
import { GET_NURSERY_DATA, NURSERY_DEMAND_STATUES } from './queries';
import { EmptyList } from './style';
import { LocationWithGroups } from './types';
import { filterFarmsWithNurseryCapacity, getLocationWaveByFarms } from './utils';

export const NurseryTabDetail = ({
  isActive,
  farms
}: {
  isActive: boolean;
  farms: OperationalFarm[];
}) => {
  const { locationUuid } = useParams();
  const { data: status } = useQuery(NURSERY_DEMAND_STATUES, {
    variables: {
      locationUuid
    },
    skip: !isActive,
    pollInterval: 5000
  });

  const isGeneratingNurseryDemand = status?.location?.isGeneratingNurseryDemand;

  const [date, setDate] = useState(() => new Date());
  const [selectedNurseryModes, setSelectedNurseryModes] = useState<NurseryMode[]>([
    NurseryMode.LIGHT,
    NurseryMode.DARK
  ]);

  const debouncedDateValue = useDebounce<Date>(date, 500);
  const [isFirstAPICall, setIsFirstAPICall] = useState(true);
  const [getNurseryData, { error, loading, data }] = useLazyQuery(GET_NURSERY_DATA, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: () => setIsFirstAPICall(false)
  });

  useEffect(() => {
    if (isActive && !isGeneratingNurseryDemand)
      getNurseryData({
        variables: {
          locationUuid,
          fromDate: toIsoDate(getMonday(date)),
          toDate: toIsoDate(addDays(date, 6))
        }
      });
  }, [locationUuid, getNurseryData, date, isActive, isGeneratingNurseryDemand]);

  const setToday = () => setDate(new Date());
  const setWeekBefore = () => setDate(subDays(date, 7));
  const setWeekAfter = () => setDate(addDays(date, 7));

  const lastNurseryDemandUpdatedAt = data?.location?.lastNurseryDemandUpdatedAt;
  const location = data?.location as LocationWithGroups;
  const locationWavesByDate = location?.wavesForDate?.byDate ?? [];

  const farmsWithNurseryCapacity = filterFarmsWithNurseryCapacity(farms);
  const locationWaveByFarms = getLocationWaveByFarms(
    farmsWithNurseryCapacity,
    location?.wavesForDate?.byOperationalFarm ?? []
  );

  if ((isFirstAPICall && !error) || (loading && isFirstAPICall))
    return (
      <Section>
        <h2>Nursery Overview</h2>
        <DsPlaceholder />
      </Section>
    );

  if (error && isFirstAPICall)
    return (
      <Section>
        <h2>Nursery Overview</h2>
        <Error title="Something went wrong. Please try again." error={error} />
      </Section>
    );

  if (!loading && farmsWithNurseryCapacity.length === 0)
    return (
      <Section>
        <h2>Nursery Overview</h2>
        <EmptyList lowOpacity rounded>
          No nursery farm is configured
        </EmptyList>
      </Section>
    );

  const Content = () => {
    if (loading) return <DsPlaceholder />;
    if (error) return <Error title="Something went wrong. Please try again." error={error} />;

    return (
      <>
        <Section marginBottom>
          <NurseryUtilisation
            isGeneratingNurseryDemand={isGeneratingNurseryDemand}
            lastNurseryDemandUpdatedAt={lastNurseryDemandUpdatedAt}
            date={debouncedDateValue}
            wavesByDate={locationWavesByDate}
            farms={farmsWithNurseryCapacity}
            selectedNurseryModes={selectedNurseryModes}
          />
        </Section>
        <Section>
          <NurserySystems
            isGeneratingNurseryDemand={isGeneratingNurseryDemand}
            date={debouncedDateValue}
            selectedNurseryModes={selectedNurseryModes}
            waveByFarms={locationWaveByFarms}
          />
        </Section>
      </>
    );
  };

  return (
    <Section>
      <h2>Nursery Overview</h2>
      <NurseryControls
        date={date}
        showViewBy={true}
        selectedNurseryModes={selectedNurseryModes}
        onClickToday={setToday}
        onClickWeekBefore={setWeekBefore}
        onClickWeekAfter={setWeekAfter}
        setSelectedNurseryModes={setSelectedNurseryModes}
      />
      <Content />
    </Section>
  );
};
