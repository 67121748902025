import { TaskType, Location, BatchWithFarm } from '../../types';
import { sortCallback } from '../../utils';

export const getBatchColumnTitles = (batchType: TaskType): [string, string] => {
  if (batchType === TaskType.PLANTING) return ['To Be Planted', 'Planted'];
  if (batchType === TaskType.HARVEST) return ['To Be Harvested', 'Harvested'];
  if (batchType === TaskType.PACKING) return ['To Be Packed', 'Packed'];
  return ['To Do', 'Done'];
};

const sortedBatches = (batches: BatchWithFarm[]): BatchWithFarm[] => {
  return batches.sort((a, b) => {
    if (a.cancelled && !b.cancelled) return 1;
    if (b.cancelled && !a.cancelled) return -1;
    return sortCallback(a.farm!.name, b.farm!.name);
  });
};

export const getBatchColumns = (location: Location): [BatchWithFarm[], BatchWithFarm[]] => {
  const todoBatches: BatchWithFarm[] = [];
  const doneBatches: BatchWithFarm[] = [];
  if (!location) return [todoBatches, doneBatches];

  location.farms?.forEach(farm => {
    farm.batches?.forEach(batch => {
      const batchWithFarm = { ...batch, farm: { ...farm } };
      if (batch.tasks?.every(task => task.completed)) {
        doneBatches.push(batchWithFarm);
      } else {
        todoBatches.push(batchWithFarm);
      }
    });
  });
  return [sortedBatches(todoBatches), sortedBatches(doneBatches)];
};
