import { useLazyQuery, useQuery } from '@apollo/client';
import { DsPlaceholder } from '@infarm/design-system-react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { DEFAULT_FORM_VALUES } from './constants';
import { PlantingScheduleForm } from './PlantingScheduleForm';
import { GET_PREV_SCHEDULE, GET_SCHEDULE_BY_UUID, LOCATION_FARMS } from './queries';
import {
  getFormatedConfigAndTotalTrays,
  getFirstSeedingDate,
  getGrowingCycleFromConfig
} from '../../utils/planting-schedule';
import { FormState } from './types';
import { FarmModel } from '../../types';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { getActionNameByFormState } from './utils';

const PageTitle = styled.h2`
  font-weight: 700;
  font-size: 32px;
  line-height: 60px;
`;

export const PlantingSchedule = () => {
  const { locationUuid, farmUuid, plantingScheduleUuid, type } = useParams();
  const isCreateForm = !plantingScheduleUuid;
  const { data, error, loading } = useQuery(LOCATION_FARMS, {
    variables: {
      uuid: locationUuid,
      farmUuid
    }
  });
  const isAcreFarm = data?.operationalFarm?.model === FarmModel.ACRE;
  const { data: prevSchedule, loading: isPrevScheduleLoading } = useQuery(GET_PREV_SCHEDULE, {
    variables: {
      farmUuidAsString: farmUuid
    },
    skip: !isCreateForm || loading || !isAcreFarm
  });

  const [getScheduleData, { data: scheduleData, loading: isScheduleLoading }] =
    useLazyQuery(GET_SCHEDULE_BY_UUID);

  useEffect(() => {
    if (!isCreateForm) {
      getScheduleData({ variables: { uuid: plantingScheduleUuid } });
    }
  }, [plantingScheduleUuid, getScheduleData, isCreateForm]);

  const location = data?.location;
  const availableStageRecipes = data?.stageRecipes || [];
  const selectedFarm = data?.operationalFarm;
  const prevScheduleConfiguration = prevSchedule?.plantingSchedules?.edges[0]?.configuration || {};

  if (error) return <h1>{error?.message || 'Something went wrong. Please try again'}</h1>;
  if (loading || !data || isScheduleLoading || isPrevScheduleLoading) return <DsPlaceholder />;

  let defaultFormValues = {
    ...DEFAULT_FORM_VALUES,
    selectedFarm,
    growingCycle: isAcreFarm
      ? getGrowingCycleFromConfig(prevScheduleConfiguration)
      : selectedFarm?.farmCycle
  };

  if (scheduleData?.plantingSchedule && !isCreateForm) {
    const {
      plantingSchedule: { firstPlantingDate, comment, configuration, maxPropagationDays, name }
    } = scheduleData;

    const growingCycle = isAcreFarm
      ? getGrowingCycleFromConfig(configuration)
      : selectedFarm?.farmCycle;

    defaultFormValues = {
      ...DEFAULT_FORM_VALUES,
      selectedFarm,
      growingCycle,
      firstPlantingDate: type === FormState.DUPLICATE ? '' : firstPlantingDate,
      comment: comment || '',
      configuration: getFormatedConfigAndTotalTrays(configuration, type as FormState),
      firstSeedingDate:
        type === FormState.DUPLICATE
          ? undefined
          : getFirstSeedingDate(firstPlantingDate, maxPropagationDays),
      scheduleName: type === FormState.DUPLICATE ? '' : name
    };
  }

  return (
    <>
      <Breadcrumbs
        elements={[
          {
            text: 'Locations (Gen2)',
            link: `/locations`,
            dataCy: 'PlantingSchedule__goBackThreeLevels'
          },
          {
            text: location?.name,
            link: `/locations/${locationUuid}`,
            dataCy: 'PlantingSchedule__goBackTwoLevels'
          },
          {
            text: `${selectedFarm?.name} Planting Schedules`,
            link: `/locations/${locationUuid}/${selectedFarm.uuid}/planting-schedules`,
            dataCy: 'PlantingSchedule__goBackOneLevel'
          },
          {
            text: getActionNameByFormState(type as FormState)
          }
        ]}
      />
      <PageTitle>{selectedFarm?.name}</PageTitle>
      <PlantingScheduleForm
        isSelfNurseryLocation={location?.isSelfNursery}
        defaultValues={defaultFormValues}
        availableStageRecipes={availableStageRecipes}
      />
    </>
  );
};
