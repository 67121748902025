import { isFuture, isToday } from 'date-fns';
import * as yup from 'yup';
import { FarmModel, OperationalFarm, PlantingScheduleWeeklyConfiguration } from '../../types';
import { getTotalUnitsCapacity } from '../../utils/farm';
import { getWeekDays } from './constants';

export const formSchema = () => {
  return yup.object({
    scheduleName: yup.string().trim().required('Schedule name is required'),
    growingCycle: yup.number().when('selectedFarm', {
      is: (selectedFarm: OperationalFarm) => {
        const isAcre = selectedFarm.model === FarmModel.ACRE;
        return isAcre;
      },
      then: yup.number().required(),
      otherwise: yup.number()
    }),
    firstPlantingDate: yup
      .date()
      .required('First planting date is required')
      .test('isInvalidPlantingDate', 'First planting date not feasible', firstPlantingDate => {
        return isToday(firstPlantingDate!) || isFuture(firstPlantingDate!);
      })
      .typeError('First planting date is invalid'),
    firstSeedingDate: yup.date(),
    selectedFarm: yup
      .object()
      .shape({
        uuid: yup.string().required(),
        name: yup.string().required(),
        model: yup.string().required(),
        farmCycle: yup.number().required(),
        schedulableBenches: yup.number().required(),
        traysPerBench: yup.number().required()
      })
      .required(),
    configuration: yup
      .array()
      .of(
        yup
          .object()
          .shape({
            stageRecipeUuid: yup.string().required(),
            trayType: yup.object().shape({
              uuid: yup.string().required(),
              name: yup.string().required(),
              surfaceUnits: yup.number().required()
            }),
            monday: yup.number().min(0),
            tuesday: yup.number().min(0),
            wednesday: yup.number().min(0),
            thursday: yup.number().min(0),
            friday: yup.number().min(0),
            saturday: yup.number().min(0),
            sunday: yup.number().min(0)
          })
          .required()
      )
      .when(
        ['selectedFarm', 'growingCycle'],
        // @ts-expect-error
        (selectedFarm: OperationalFarm, growingCycle: number, schema) => {
          return schema.test({
            test: (configuration: PlantingScheduleWeeklyConfiguration[]): boolean => {
              const unitsCapacity = getTotalUnitsCapacity(selectedFarm!, growingCycle);
              const { capacityUnitsPerBench } = selectedFarm!;
              let totalUnits = 0;
              const BenchesPerDay: Number[] = [];
              getWeekDays().forEach(day => {
                const totalUnitsPerDay = configuration.reduce((a, config) => {
                  return a + (config.trayType?.surfaceUnits || 0) * (config[day] || 0);
                }, 0);
                totalUnits = totalUnits + totalUnitsPerDay;
                BenchesPerDay.push(totalUnitsPerDay / (capacityUnitsPerBench || 1)).toFixed(2);
              });
              const hasMoreOrLessUnits = totalUnits !== unitsCapacity;
              const isBenchPartialPerDay = BenchesPerDay.some(i => !Number.isInteger(i));
              const isAcre = selectedFarm.model === FarmModel.ACRE;

              return isAcre
                ? totalUnits !== 0 && !hasMoreOrLessUnits && !isBenchPartialPerDay
                : totalUnits !== 0 && !hasMoreOrLessUnits;
            }
          });
        }
      ),
    hasDeficit: yup.boolean(),
    hasSurplus: yup.boolean()
  });
};
