import { DsColumn, DsIcon, DsButton, DsRow } from '@infarm/design-system-react';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';

export const Section = styled.section<{
  borderTop?: boolean;
  marginBottom?: Boolean;
}>`
  margin-top: 24px;
  padding-top: 24px;
  ${({ borderTop }) => borderTop && 'border-top: 1px solid rgba(0, 0, 0, 0.11);'}
  ${({ marginBottom }) => marginBottom && 'margin-bottom: 64px;'}
`;

export const InputSection = styled(Section)`
  margin-top: 24px;
  padding-top: 0;
`;

export const SectionTitleWrapper = styled(DsColumn)`
  margin-bottom: 24px;
`;

export const SectionTitle = styled.h3`
  font-weight: 500;
`;

export const SectionSubTitle = styled.p`
  color: #999999;
  font-weight: 400;
`;

export const SectionNote = styled.p`
  color: #007aff;
  margin-top: 24px;
`;

export const InputTable = styled.table`
  width: 100%;
  border-spacing: 0;
  outline: thin solid #e0e0e0;
  border-radius: 10px;

  .closeIcon {
    text-align: center;
  }
`;

export const Row = styled.tr`
  padding: 0 16px;

  &.empty {
    outline: 2px solid #fa6400;
  }
`;

export const BlackRow = styled(Row)`
  background-color: black;
`;

export const Cell = styled.td`
  border: thin solid #e0e0e0;
  padding: 0 16px;
`;

export const HeaderCell = styled.th`
  padding-left: 16px;
  text-align: left;
  &:first-of-type {
    border-top-left-radius: 10px;
  }
  &:last-of-type {
    border-top-right-radius: 10px;
  }
`;

export const MondayHeaderCell = styled(HeaderCell)`
  padding: 14px;
`;

export const ChosenDayWrapper = styled.div`
  padding: 16px;
  text-align: left;
`;

export const ArrowWrapper = styled.div``;

export const LeftArrowEnd = styled.div`
  margin-left: 20px;
`;

export const TueToSatLeaf = styled.div<{ isHubDemandForm?: boolean }>`
  position: absolute;
  margin-top: -10px;
  margin-left: ${({ isHubDemandForm }) => (isHubDemandForm ? '18px' : '11px')};
`;

export const MondayLeaf = styled.span<{ isHubDemandForm?: boolean }>`
  position: absolute;
  margin-top: -10px;
  margin-left: ${({ isHubDemandForm }) => (isHubDemandForm ? '18px' : '11px')};
`;

export const MondayRightArrowEnd = styled.span`
  position: absolute;
`;

export const MondayRightArrow = styled.div``;

export const MondayDashArrowStart = styled.div<{ isHubDemandForm?: boolean }>`
  position: absolute;
  margin-left: ${({ isHubDemandForm }) => (isHubDemandForm ? '20px' : '40px')};
`;

export const TueToSatPlantDay = styled.span<{ isHubDemandForm?: boolean }>`
  position: absolute;
  margin-left: ${({ isHubDemandForm }) => (isHubDemandForm ? '40px' : '56px')};
`;

export const MondayPlantDay = styled.span`
  position: absolute;
  margin-left: 73px;
`;

export const EndOfColumn = styled.div`
  float: right;
`;

export const SundayFirstArrowEnd = styled.span`
  margin-left: 30px;
`;

export const SundayDashArrowStart = styled.span<{ isHubDemandForm?: boolean }>`
  margin-left: ${({ isHubDemandForm }) => (isHubDemandForm ? '36px' : '62px')};
  margin-top: 12.7px;
  position: absolute;
`;

export const SundayDashed = styled.span``;

export const SundayLeaf = styled.div<{ isHubDemandForm?: boolean }>`
  position: absolute;
  margin-top: 2px;
  margin-left: ${({ isHubDemandForm }) => (isHubDemandForm ? '36px' : '28px')};
`;

export const DashArrowEnd = styled.div`
  position: absolute;
  padding-right: 2px;
`;

export const StyledTh = styled.th`
  display: hidden;
`;

export const RightArrowStart = styled.div`
  float: right;
`;

export const RightArrowEnd = styled.span`
  margin-right: -50px;
`;

export const EmptyRow = styled.tr`
  height: 16px;
`;

export const TotalCount = styled.span<{ error?: boolean }>`
  ${({ error }) => error && 'color: #FF3B30;'}
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  h4 {
    font-size: 18px;
  }

  span {
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
  }
`;

export const ActionRowWrapper = styled.tr`
  height: 28px;

  td:first-child {
    background-color: #f5f5f5;
  }
`;

export const ActionIcon = styled(DsIcon)`
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
`;

export const ErrorMessage = styled.p`
  color: #ff3b30;
  display: flex;
  margin-top: 8px;
  ds-icon {
    margin-right: 10px;
  }
`;

export const WarningIcon = styled(DsIcon)`
  position: absolute;
  bottom: 9px;
  right: 56px;
  color: #ffc647;
`;

export const StyledInput = styled(TextField)<{ $warning?: boolean }>`
  &.scheduleNameInput {
    width: 288px;
  }
  & .MuiInput-underline:after {
    border-bottom-color: ${({ $warning }) => ($warning ? '#FA6400' : '#757575')};
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: ${({ $warning }) => ($warning ? '#FA6400' : '#757575')};
    }
    & input[type='date']::-webkit-calendar-picker-indicator {
      cursor: pointer;
    }
    &.Mui-focused fieldset {
      border-color: ${({ $warning }) => ($warning ? '#FA6400' : '#757575')};
    }
    &.Mui-error fieldset {
      border-color: #f44336;
    }
  }
`;

export const SectionDates = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  label {
    font-weight: 700;
    line-height: 20px;
    font-size: 16px;
  }
`;

export const DatesWrapper = styled.div`
  display: flex;
  flex-grow: 2.5;
  margin-left: 48px;
  margin-bottom: 28px;

  div:first-child {
    margin-right: 120px;
  }
`;

export const SeedingDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;

  label {
    margin-bottom: 8px;
  }
`;

export const CommentSectionTitleWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 24px;

  span {
    color: rgba(0, 0, 0, 0.4);
    font-size: 16px;
    font-weight: normal;
    margin-left: 8px;
  }
`;

export const CancelButton = styled(DsButton)`
  margin-right: 12px;
`;
export const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Overlay = styled.div`
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #000000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OverlayText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
`;

export const PasteButton = styled.div`
  align-self: flex-start;
  border: 1px solid;
  border-radius: 4px;
  width: 198px;
  height: 44px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export const PasteIcon = styled(DsIcon)`
  margin-right: 4px;
  align-self: flex-end;
`;

export const Container = styled.div``;

export const StyledSpan = styled.span`
  margin-left: -17px;
`;

export const DayIndicatorWrapper = styled.div`
  position: absolute;
  margin-top: -10px;
  margin-left: 10px;
`;

export const PlantingDateIndicator = styled.div`
  margin-top: 14px;
`;

export const HeaderCellTopLeftBorder = styled.th`
  padding: 7px;
  text-align: left;
  border-top-left-radius: 10px;
`;

export const PlatingDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`;

export const PlatingDateText = styled.div`
  font-weight: normal;
  font-size: 14px;
`;

export const LastArrowStartTueToSat = styled.span``;

export const ModalHeading = styled.div`
  min-width: 500px;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
`;

export const ModalText = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding-top: 4px;
  max-width: 500px;
`;

export const ModalButtonsRow = styled(DsRow)`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  padding-bottom: 4px;
`;
