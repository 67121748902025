import { DsRow, DsButton } from '@infarm/design-system-react';
import { FormHelperText, InputAdornment, OutlinedInput } from '@material-ui/core';
import { addDays, isValid } from 'date-fns';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { useLazyQuery, useQuery } from '@apollo/client';
import { DOWNLOAD_NIA_SEEDING_PLAN, NURSERY_DEMAND_STATUES } from './queries';
import { Error } from '../../components/Error';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { offsetDateWithTimezone, toIsoDate } from '../../utils/date-functions';

const DatePickerWrapper = styled.div`
  width: 275px;
`;

const Input = styled(OutlinedInput)`
  & .MuiOutlinedInput-input {
    padding: 9px 14px;
    width: 200px;
  }
`;

export const DownloadNiASeedingPlan = () => {
  const { locationUuid, date } = useParams();
  const [fromDate, setFromDate] = useState<Date | null>(offsetDateWithTimezone(date || new Date()));
  const [toDate, setToDate] = useState<Date | null>(offsetDateWithTimezone(date || new Date()));
  const { data: status } = useQuery(NURSERY_DEMAND_STATUES, {
    variables: {
      locationUuid
    },
    pollInterval: 5000
  });
  const isGeneratingNurseryDemand = status?.location?.isGeneratingNurseryDemand;

  const [downloadNiaSeedingPlan, { error: seedingPlanError, loading: isSeedinPlanLoading }] =
    useLazyQuery(DOWNLOAD_NIA_SEEDING_PLAN, {
      fetchPolicy: 'network-only',
      onCompleted: data => {
        const { location } = data;
        const {
          seedingPlan: {
            nia: { file }
          }
        } = location;
        const downloadLink = document.createElement('a');
        const today = new Date();
        const time = `${today.getHours()}.${today.getMinutes()}.${today.getSeconds()}`;
        const fileName = `${location.name}-seeding-plan-${toIsoDate(fromDate!)}T${time}.xlsx`;

        downloadLink.href = file;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    });
  const onDownloadSeedingPlan = async (): Promise<void> => {
    if (!locationUuid || !isValid(fromDate) || !isValid(toDate)) return;
    const startSeedingDate = toIsoDate(fromDate as Date);
    const endSeedingDate = toIsoDate(toDate as Date);
    downloadNiaSeedingPlan({
      variables: {
        startSeedingDate,
        endSeedingDate,
        nurseryUuid: locationUuid
      }
    });
  };

  const onSeedingDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };
  const getMaxDate = () => {
    if (fromDate && !toDate) {
      return addDays(fromDate, 7);
    }
    return null;
  };
  return (
    <div>
      <FormHelperText id="seeding-date">Seeding Date</FormHelperText>
      <DsRow>
        <DatePickerWrapper>
          <DatePicker
            selected={fromDate}
            onChange={onSeedingDateChange}
            startDate={fromDate}
            endDate={toDate}
            maxDate={getMaxDate()}
            selectsRange
            dateFormat="dd MMM yyyy"
            customInput={
              <Input
                aria-describedby="seeding-date"
                type="text"
                endAdornment={
                  <InputAdornment position="end">
                    <DateRangeIcon />
                  </InputAdornment>
                }
              />
            }
          />
        </DatePickerWrapper>
        <DsButton
          primary
          label="Download Seeding Plan"
          onClick={onDownloadSeedingPlan}
          disabled={isSeedinPlanLoading || isGeneratingNurseryDemand}
        />
      </DsRow>
      {isGeneratingNurseryDemand && (
        <p className="MuiFormHelperText-root">
          Demand is being processed. You can download again once ready.
        </p>
      )}
      {seedingPlanError && <Error error={seedingPlanError} />}
    </div>
  );
};
