import { gql } from '@apollo/client';

export const PLANT_PRODUCTION = gql`
  query PlantProductionForLocation(
    $locationUuid: ID!
    $fromDate: Date!
    $toDate: Date!
    $taskType: TaskType!
    $farmStateNot: OperationalFarmState
  ) {
    location(uuid: $locationUuid) {
      uuid
      category
      tasksForDate(
        fromDate: $fromDate
        toDate: $toDate
        filter: { taskType: [$taskType], farmStateNot: $farmStateNot, isNursery: false }
      ) {
        totalNumberOfTrays
        totalNumberOfPlants
        totalNumberOfBenches
        byDate {
          date
          taskGroup {
            totalNumberOfPlants
            totalNumberOfTrays
            totalNumberOfBenches
          }
        }
        byOperationalFarm {
          operationalFarm {
            uuid
          }
          taskGroup {
            byDate {
              date
              taskGroup {
                totalNumberOfPlants
                totalNumberOfTrays
                totalNumberOfBenches
              }
            }
            byStageRecipe {
              stageRecipe {
                uuid
                name
                internalCode
                weight
              }
              taskGroup {
                byDate {
                  date
                  taskGroup {
                    totalNumberOfPlants
                    totalNumberOfTrays
                    totalNumberOfBenches
                  }
                }
              }
            }
          }
        }
        byStageRecipe {
          stageRecipe {
            uuid
            name
            internalCode
            weight
          }
          taskGroup {
            byDate {
              date
              taskGroup {
                totalNumberOfPlants
                totalNumberOfTrays
              }
            }
            byOperationalFarm {
              operationalFarm {
                uuid
                name
                model
              }
              taskGroup {
                byDate {
                  date
                  taskGroup {
                    totalNumberOfPlants
                    totalNumberOfTrays
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
