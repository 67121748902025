import { sortCallback } from '.';
import { OperationalFarm, TaskType } from '../types';

export const getWeeklyBenchCapacity = (
  { schedulableBenches }: OperationalFarm,
  growingCycle: number
) => {
  if (schedulableBenches && growingCycle) return schedulableBenches / (growingCycle / 7);
  return 0;
};

export const getTotalUnitsCapacity = (farm: OperationalFarm, growingCycle: number) => {
  return getWeeklyBenchCapacity(farm, growingCycle) * (farm.capacityUnitsPerBench || 0);
};

export const sortFarms = (farms: OperationalFarm[]) => {
  // copy array and then sort because farms array is frozen

  return farms
    .slice()
    .sort((a: OperationalFarm, b: OperationalFarm) => sortCallback(a.name, b.name));
};

export const displayTaskType = (taskType: TaskType): string => {
  if (taskType === TaskType.PLANTING) return 'Planting';
  if (taskType === TaskType.HARVEST) return 'Harvesting';
  if (taskType === TaskType.PACKING) return 'Packing';
  return '';
};
