import React, { useEffect, useMemo, useState } from 'react';
import { DsButton, DsTab, DsTabContainer } from '@infarm/design-system-react';
import { Link } from 'react-router-dom';
import { NetworkStatus, useLazyQuery } from '@apollo/client';
import { useDebouncedCallback } from 'use-debounce';
import { GET_LOCATIONS } from './queries';
import { PageHeader, Row, Search } from './styles';
import { LocationTable } from './LocationTable';
import { Tab } from './constants';
import {
  inStoreCategoryValues,
  inHubCategoryValues,
  allCategoryValues,
  writeLocationAllowedRoles
} from '../constants';
import { hasPermission } from '../../utils/user';

interface TabDetailsProps {
  tab: Tab;
  setTotalCount: (count: number) => void;
  searchName: string;
}

const TabDetails = React.memo(({ tab, setTotalCount, searchName }: TabDetailsProps) => {
  const [getLocations, { data, error, fetchMore, networkStatus }] = useLazyQuery(GET_LOCATIONS, {
    notifyOnNetworkStatusChange: true
  });

  const categories = useMemo(
    () =>
      tab === Tab.INSTORE
        ? inStoreCategoryValues
        : tab === Tab.INHUB
        ? inHubCategoryValues
        : allCategoryValues,
    [tab]
  );

  const locations = data?.locations?.edges;
  const pageInfo = data?.locations?.pageInfo;
  const count = pageInfo?.totalCount;

  const loading = networkStatus === NetworkStatus.loading;
  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;

  useEffect(() => {
    if (count !== undefined) setTotalCount(count);
  }, [count, setTotalCount]);

  useEffect(() => {
    getLocations({
      variables: {
        filter: {
          category: categories,
          name: searchName
        }
      }
    });
  }, [searchName, getLocations, categories]);

  const fetchMoreLocations = () => {
    if (fetchMore)
      fetchMore({
        variables: {
          cursor: data?.locations?.pageInfo?.endCursor
        }
      });
  };

  return (
    <>
      <LocationTable
        loading={loading}
        error={error}
        locations={locations}
        tab={tab}
        fetchMoreError={data?.locations?.error}
      />
      {!!locations?.length && (
        <Row>
          <DsButton
            label="Show More"
            secondary
            disabled={!pageInfo?.hasNextPage || isFetchingMore}
            onClick={fetchMoreLocations}
          />
        </Row>
      )}
    </>
  );
});

export const LocationList = () => {
  const [selectedTab, setSelectedTab] = useState(Tab.ALL);
  const [allTotalCount, setAllTotalCount] = useState(0);
  const [inStoreTotalCount, setInstoreTotalCount] = useState(0);
  const [inHubTotalCount, setInHubTotalCount] = useState(0);
  const [searchName, setSearchName] = useState('');

  const debounced = useDebouncedCallback(
    value => {
      setSearchName(value);
    },
    // delay in ms
    500
  );

  const isWriteLocationAllowed = hasPermission(writeLocationAllowedRoles);
  return (
    <>
      <PageHeader>
        <h1>Locations (Gen2)</h1>
        {isWriteLocationAllowed && (
          <Link to="create">
            <DsButton label="New Location" data-cy="LocationList___addNewLocation" primary />
          </Link>
        )}
      </PageHeader>
      <Search
        placeholder="Search location"
        icon="search"
        type="search"
        onInput={(e: React.FormEvent<HTMLDsTextfieldElement>) =>
          debounced((e.target as HTMLInputElement).value)
        }
      />
      <DsTabContainer>
        <DsTab
          selected={selectedTab === Tab.ALL}
          label={`${Tab.ALL} (${allTotalCount})`}
          onClick={() => setSelectedTab(Tab.ALL)}
        >
          <TabDetails tab={Tab.ALL} setTotalCount={setAllTotalCount} searchName={searchName} />
        </DsTab>
        <DsTab
          selected={selectedTab === Tab.INSTORE}
          label={`${Tab.INSTORE} (${inStoreTotalCount})`}
          onClick={() => setSelectedTab(Tab.INSTORE)}
        >
          <TabDetails
            tab={Tab.INSTORE}
            setTotalCount={setInstoreTotalCount}
            searchName={searchName}
          />
        </DsTab>
        <DsTab
          selected={selectedTab === Tab.INHUB}
          label={`${Tab.INHUB} (${inHubTotalCount})`}
          onClick={() => setSelectedTab(Tab.INHUB)}
        >
          <TabDetails tab={Tab.INHUB} setTotalCount={setInHubTotalCount} searchName={searchName} />
        </DsTab>
      </DsTabContainer>
    </>
  );
};
