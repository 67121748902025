import { FC, useEffect, useState } from 'react';
import { FarmContainer } from '../style';
import { HeaderRow } from './HeaderRow';
import { generateDaysOfWeek, toIsoDate } from '../../../../../utils/date-functions';
import { NurseryMode } from '../../../../constants';
import { StageRecipeRow } from './StageRecipeRow';
import { ExpandedRowData } from './types';
import { getTraysByNurseryModes } from '../../utils';
import { LocationWaveByFarm } from '../../types';

interface NurserySystemTableProps {
  waveByFarm: LocationWaveByFarm;
  date: Date;
  selectedNurseryModes: NurseryMode[];
}

export const NurserySystemTable: FC<NurserySystemTableProps> = ({
  waveByFarm,
  date,
  selectedNurseryModes
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const [expandedRowData, setExpandedRowData] = useState<{ [date: string]: ExpandedRowData }>({});
  const toggleExpand = () => setExpanded(curr => !curr);
  const weekDates = generateDaysOfWeek(date);
  const isFarmActive = waveByFarm.waveGroup.totalNumberOfTrays;

  useEffect(() => {
    const updateValues = () => {
      const tempExpandedRowData: { [date: string]: ExpandedRowData } = {};
      generateDaysOfWeek(date).forEach(weekDate => {
        const isoWeekDate = toIsoDate(weekDate);
        const { waveGroup } =
          waveByFarm.waveGroup.byDate.find(
            waveGroupByDate => waveGroupByDate.date === isoWeekDate
          ) ?? {};

        tempExpandedRowData[isoWeekDate] = {
          totalNumberOfTrays: waveGroup
            ? getTraysByNurseryModes(waveGroup, selectedNurseryModes)
            : 0
        };
      });
      setExpandedRowData(tempExpandedRowData);
    };
    updateValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waveByFarm, date]);

  return (
    <FarmContainer isInactive={!isFarmActive}>
      <HeaderRow
        waveByFarm={waveByFarm}
        data={expandedRowData}
        isExpanded={isExpanded}
        toggleExpand={toggleExpand}
        dateHeaders={weekDates}
        selectedNurseryModes={selectedNurseryModes}
      />
      {isExpanded &&
        waveByFarm.waveGroup.byStageRecipe?.map((waveGroupsByStageRecipe, idx) => (
          <StageRecipeRow
            waveGroupsByStageRecipe={waveGroupsByStageRecipe}
            date={date}
            key={idx}
            selectedNurseryModes={selectedNurseryModes}
          />
        ))}
    </FarmContainer>
  );
};
