import { FC } from 'react';
import { DsButton } from '@infarm/design-system-react';
import { Link } from 'react-router-dom';
import { ImageLabel } from '../../../../components/ImageLabel';
import { TrackVisualizationModal } from '../../../../components/TrackVisualizationModal';
import { isToday } from '../../../../utils/date-functions';
import {
  AnchorTagUnderline,
  ButtonContainer,
  ControlsRow,
  DataRow,
  FlexStyledSpan,
  GreyP,
  GreyText,
  LeftBorderdSpan,
  RedText,
  Row,
  Span
} from '../../style';
import { FarmModel, FarmState, OperationalFarm, TaskType } from '../../../../types';
import InstoreImage from '../../../../assets/instore-v2_farm_front_icon.png';
import AcreImage from '../../../../assets/acre.svg';
import { userIsAdmin } from '../../../../utils/user';
import { UnitFilter } from '../../constants';
import { formatRowData } from '../utils';
import { LocationTaskGroupByOperationalFarm } from '../types';

const FarmDetail: FC<{ farm: OperationalFarm }> = ({ farm }) => {
  const { model, serialNumber } = farm;
  let greyText = model === FarmModel.INSTOREV2 ? 'INSF v2.0 · ' : model + ' · ';
  if (serialNumber) greyText = greyText + `ID ${serialNumber}`;

  return (
    <div>
      <GreyText>{greyText}</GreyText>
      {!serialNumber && <RedText>Farm ID missing</RedText>}
    </div>
  );
};

const FarmName: FC<{ farm: OperationalFarm }> = ({ farm }) => {
  if (!farm.serialNumber) return <span>{farm.name}</span>;
  return <AnchorTagUnderline to={`/farms/${farm.serialNumber}`}>{farm.name}</AnchorTagUnderline>;
};

interface Props {
  isExpanded: boolean;
  toggleExpand: () => void;
  taskGroupByOperationalFarm: LocationTaskGroupByOperationalFarm;
  selectedUnitFilter: UnitFilter;
  selectedTaskType: TaskType;
  weekDates: string[];
}

export const FarmHeaderRow: FC<Props> = ({
  isExpanded,
  toggleExpand,
  taskGroupByOperationalFarm,
  selectedUnitFilter,
  selectedTaskType,
  weekDates
}) => {
  const shouldDisplayTrackVisualisation =
    (globalThis as any).features?.track_visualization && userIsAdmin();
  const { operationalFarm, taskGroup } = taskGroupByOperationalFarm;
  const shouldDisplayPlantingSchedule =
    operationalFarm.state !== FarmState.ARCHIVED && operationalFarm.model === FarmModel.INSTOREV2;

  const rowData = formatRowData(taskGroup?.byDate);

  return (
    <Row rounded={!isExpanded} topRounded={isExpanded}>
      <FlexStyledSpan className="span-3">
        <ImageLabel
          image={operationalFarm.model === FarmModel.ACRE ? AcreImage : InstoreImage}
          title={<FarmName farm={operationalFarm} />}
          subTitle={<FarmDetail farm={operationalFarm} />}
          alt={operationalFarm.name}
        />
        <ControlsRow>
          {shouldDisplayPlantingSchedule && (
            <Link to={`${operationalFarm.uuid}/planting-schedules`}>
              <DsButton
                icon="date_range"
                data-cy={`FarmPlantingSchedule___${operationalFarm.uuid}`}
              />
            </Link>
          )}
          {shouldDisplayTrackVisualisation && (
            <TrackVisualizationModal farm={operationalFarm} dateInterval={30} />
          )}
        </ControlsRow>
      </FlexStyledSpan>
      <DataRow className="span-7">
        {taskGroup?.byDate?.length ? (
          weekDates.map(weekDate => (
            <LeftBorderdSpan key={weekDate}>
              <Span data-cy={`FarmPlantingSchedule___${weekDate}`} bold={isToday(weekDate)}>
                {rowData[weekDate]?.[selectedUnitFilter] ?? 0}
              </Span>
            </LeftBorderdSpan>
          ))
        ) : (
          <LeftBorderdSpan className="span-7">
            <GreyP>
              {`There is no ${(selectedTaskType === TaskType.PLANTING
                ? selectedTaskType
                : selectedTaskType + 'ing'
              ).toLowerCase()} scheduled for this week`}
            </GreyP>
          </LeftBorderdSpan>
        )}
      </DataRow>
      <ButtonContainer>
        <DsButton
          icon={isExpanded ? 'expand_less' : 'expand_more'}
          flat
          disabled={!taskGroup?.byDate?.length}
          data-cy="FarmPlantingSchedule___expandLessMore"
          onClick={toggleExpand}
        />
      </ButtonContainer>
    </Row>
  );
};
