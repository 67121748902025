import { TableRow } from './TableRow';
import { Tr, TotalRow, Td, Th, Tbody, TotalCell } from './style';
import { TableBodyProps } from '.';

export const TableBody = (props: TableBodyProps) => {
  const { rows, excessRows, totalNumberOfTrays, showCommitButton } = props;

  if (!rows?.length)
    return (
      <tbody>
        <Tr>
          <Td colSpan={2}>No data is available at the moment</Td>
        </Tr>
      </tbody>
    );
  return (
    <Tbody data-cy="LocationTable___tableBody">
      {rows.map(row => (
        <TableRow {...row} showCommitButton={showCommitButton} key={row.rowData.uuid} />
      ))}
      {excessRows?.length && (
        <>
          <Tr>
            <Th colSpan={showCommitButton ? 3 : 2}>Excess demand</Th>
          </Tr>
          {excessRows.map(row => (
            <TableRow
              {...row}
              showCommitButton={showCommitButton}
              key={`excess-${row.rowData.uuid}`}
            />
          ))}
        </>
      )}
      <TotalRow data-cy={`table-row-total`}>
        <TotalCell bold>Total</TotalCell>
        <TotalCell>{totalNumberOfTrays}</TotalCell>
        {showCommitButton && <TotalCell></TotalCell>}
      </TotalRow>
    </Tbody>
  );
};
